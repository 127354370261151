import * as echarts from "echarts";
import HomeConfigDashboardPage from '@/views/HomeConfigDashboard/index.vue'
import HomeMeterShowPage from '@/views/HomeMeterShow/index.vue'
import HomeAccountDetailPage from '@/views/HomeAccountDetail/index.vue'

export default {
  name: 'HomePage',
  components: {
    HomeConfigDashboardPage,
    HomeMeterShowPage,
    HomeAccountDetailPage
  },
  data() {
    return {
      propertyState: false,
      // 账号数据
      accountInfo: {},
      chartShowTime: '1',
      // 图表对象
      chartlist: {},
      chartShowData: [],
      // 刷新图表时间
      refreshrateChart: 0,
      // 刷新图表时间事件
      timingRefreshChart: null,
      chartsyslist: [],
      selectChartShowData: [],

      dashboardlist: [],
      // 仪表图表对象
      pirChartlist: {},

      dashboard: [],
      dashboardnum: '',
      dashboardConfig: [],
      // 刷新仪表时间
      refreshrateDashboard: 0,
      // 刷新仪表时间事件
      timingRefreshDashboard: null,
      // 配置仪表弹窗
      configDashboardVisible: false,
      configEditData: null,

      // 仪表详情弹窗
      dialogMeterChartVisible: false,
      meterChartDetail: null,
      // 用户详情弹窗
      dialogAccountDetailVisible: false,
      dialogAccountType: null,

    }
  },
  mounted() {
    this.getDashboard()
    this.getAccountData()
    this.getchartsyslist(() => {
      this.chartShowData.forEach((element) => {
        this.getchartlist([element])
      })
    })
    const funcs = () => {
      for (const key in this.chartlist) {
        if (this.chartlist[key].chartBox) {
          // 清空数据
          this.chartlist[key].data = []
          // 销毁实例释放资源
          this.chartlist[key].chartBox && this.chartlist[key].chartBox.dispose()
          this.chartlist[key].chartBox = null
        }
      }
      this.getDashboard()
      this.getAccountData()
      this.getchartsyslist(() => {
        this.chartShowData.forEach((element) => {
          this.getchartlist([element])
        })
      })
    }
    this.$setLanguageChangeCallback(funcs)

    window.addEventListener("resize", () => {
      this.changeChartSize()
    })
  },
  computed: {
    isHideTitle() {
      return this.chartShowData.length == 0 || (this.chartShowData.length == 1 && this.chartShowData[0] == 0)
    },
    accountDetailTit() {
      switch (this.dialogAccountType) {
        case 'pe':
          return this.$t('home.proentity')

        case 'acc':
          return this.$t('home.homeAccount')

        case 'loc':
          return this.$t('home.homeLoc')

        case 'rctask':
          return this.$t('home.instask')

        case 'wotemp':
          return this.$t('home.woTemplate')

        default:
          return ''
      }
    },
    // 获取图表显示时间描述
    chartTimeRangeLabel() {
      let labels = ['nearlySevenDays', 'nearlyThirtyDays', 'nearlyNinetyDays', 'thisMonth', 'thisQuarter', 'lastQuarter', 'thisYear', 'prevYear',]
      return this.$t(`homeConfigDashboard.${labels[this.chartShowTime - 1]}`)
    }
  },
  filters: {
    radianProgress(item) {
      let currentValue = Math.abs(item.newreading - item.islowlimit) * (180 / (item.istoplimit - item.islowlimit))
      if (isNaN(currentValue)) {
        currentValue = 0
      }
      if (item.newreading < item.islowlimit) {
        currentValue = 0
      }
      if (item.newreading > item.istoplimit && item.istoplimit != 0) {
        currentValue = 180
      }
      return 'transform:rotate(' + currentValue + 'deg)'
    },
    transverseProgress(item) {
      let currentValue = item.newreading * (100 / item.istoplimit)
      if (isNaN(currentValue)) {
        currentValue = 0
      }
      if (item.newreading < item.islowlimit) {
        currentValue = 0
      }
      if (item.newreading > item.istoplimit && item.istoplimit != 0) {
        currentValue = 100
      }
      return 'width:' + currentValue + '%'
    },
  },
  beforeDestroy() {
    clearInterval(this.timingRefreshChart)
    clearInterval(this.timingRefreshDashboard)
  },
  methods: {
    // 时间间隔
    friendlyFormatTime(stringTime) {
      if (stringTime == '') return ''
      let str = stringTime;
      //将字符串转换成时间格式
      let timePublish = new Date(str);
      let timeNow = new Date();
      let minute = 1000 * 60;
      let hour = minute * 60;
      let day = hour * 24;
      let month = day * 30;
      let year = month * 12;
      let diffValue = timeNow - timePublish;
      let diffMonth = diffValue / month;
      let diffWeek = diffValue / (7 * day);
      let diffDay = diffValue / day;
      let diffHour = diffValue / hour;
      let diffMinute = diffValue / minute;
      let diffYear = diffValue / year;
      let result = null
      if (diffValue < 0) {
        result = this.$t('home.just')
      } else if (diffYear > 1) {
        result = parseInt(diffYear) + this.$t('home.yearsAgo')
        if (diffYear < 2) {
          result = parseInt(diffYear) + this.$t('home.yearAgo')
        }
      } else if (diffMonth > 1) {
        result = parseInt(diffMonth) + this.$t('home.monthsAgo')
        if (diffMonth < 2) {
          result = parseInt(diffMonth) + this.$t('home.monthAgo')
        }
      } else if (diffWeek > 1) {
        result = parseInt(diffWeek) + this.$t('home.weeksAgo')
        if (diffWeek < 2) {
          result = parseInt(diffWeek) + this.$t('home.weekAgo')
        }
      } else if (diffDay > 1) {
        result = parseInt(diffDay) + this.$t('home.daysAgo')
        if (diffDay < 2) {
          result = parseInt(diffDay) + this.$t('home.dayAgo')
        }
      } else if (diffHour > 1) {
        result = parseInt(diffHour) + this.$t('home.hoursAgo')
        if (diffHour < 2) {
          result = parseInt(diffHour) + this.$t('home.hourAgo')
        }
      } else if (diffMinute > 1) {
        result = parseInt(diffMinute) + this.$t('home.minutesAgo')
        if (diffMinute < 2) {
          result = parseInt(diffMinute) + this.$t('home.minuteAgo')
        }
      } else {
        result = this.$t('home.just')
      }
      // console.log('result', result);

      return result;
    },
    // 监听浏览器缩放刷新图表
    changeChartSize() {
      this.$nextTick(() => {
        for (let nn in this.chartlist) {
          if (this.$refs[nn.toLowerCase()] && this.chartlist[nn] && this.chartlist[nn]['chartBox']) {
            this.chartlist[nn]['chartBox'].resize()
          }
        }
        for (let i in this.pirChartlist) {
          if (this.pirChartlist[i]) {
            this.pirChartlist[i].resize()
          }
        }
        // for (let i in this.meterChartList) {
        // 	if (this.meterChartList[i]) {
        // 		this.meterChartList[i].resize()
        // 	}
        // }
      })
    },
    // 显示仪表标题icon
    showMeterChartIcon(icon) {
      if (icon == 'CO2' || icon == 'HYGRO' || icon == 'PRES' || icon == 'PM10' || icon == 'HCHO' || icon == 'TVOC' || icon == 'LIGHT' || icon == 'PIR') {
        return ['el-icon-' + icon.toLowerCase() + '-icon']
      } else if (icon == 'PM2_5') {
        return ['el-icon-pm25-icon']
      }
      return ['el-icon-thermo-icon']
    },
    // 切换是否显示全部物业
    switchPropertyState() {
      this.propertyState = !this.propertyState

      for (const key in this.chartlist) {
        if (this.chartlist[key].chartBox) {
          // 清空数据
          this.chartlist[key].data = []
          // 销毁实例释放资源
          this.chartlist[key].chartBox && this.chartlist[key].chartBox.dispose()
          this.chartlist[key].chartBox = null
        }
      }
      this.getAccountData()
      this.chartShowData.forEach((element) => {
        this.getchartlist([element])
      })
    },
    // 图表显示loading
    showChartLoading(chart) {
      // console.log('chart', chart);
      if (chart) {
        echarts.init(chart).showLoading({
          text: '',
          color: '#ffa000',
        })
      }
    },
    // 获取用户数据
    getAccountData() {
      // this.isAccountLoading = true
      let chartList = ['RCWO', 'WO', 'IR']
      this.$nextTick(() => {
        for (let key of chartList) {
          let domKey = key.toLowerCase()
          if (this.$refs[domKey]) {
            this.chartlist[key] = {
              data: null,
              dom: this.$refs[domKey]
            }
            this.showChartLoading(this.$refs[domKey])
          }
        }
        this.$http.post('/home/chartlist', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
          "charts": ['chart0'],
          "datascope": this.chartShowTime,
          isallpe: this.propertyState + '',
        }).then((response) => {
          if (response.data.success) {
            this.accountInfo = response.data['ACCINFO']
            // this.$nextTick(() => {
            chartList.forEach(index => {
              if (this.chartlist[index]) {
                this.chartlist[index].data = response.data[index]
              }
            })
            this.createSummaryPieChart()
            // })
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
      })
    },
    // 生成图表
    createSummaryPieChart() {
      // 取消'#BEC0D2'、已完成'#FF7234'、进行中'#0053D0'、超时'#ffc64b'、待执行'#9013FE'、暂搁置'#FF9649'
      // 巡检单 RCWO
      let colorlist = ['#BEC0D2', '#FF7234', '#0053D0', '#FF9649', '#9013FE', '#FF9649']
      // 工单 WO
      let woColorList = ['#BEC0D2', '#FF7234', '#0053D0', '#9013FE', '#FF9649']
      // 事件报告 IR
      let ircolorlist = ['#BEC0D2', '#FF7234', '#9013FE']
      // 巡检单
      if (this.chartlist['RCWO'] && this.$refs['rcwo']) {
        this.chartlist['RCWO']['chartBox'] = echarts.init(this.chartlist['RCWO']['dom'])
        if (this.chartlist['RCWO']['data'][0].sumcount > 0) {
          this.chartlist['RCWO']['chartBox'].hideLoading()
          this.chartlist['RCWO']['chartBox'].setOption({
            title: {
              show: false
            },
            tooltip: {
              // trigger: 'item',
              formatter: "{b} : {d}%"
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            series: [{
              type: 'pie',
              radius: ['45%', '65%'],
              center: ['50%', '35%'],

              itemStyle: {
                // borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: [{
                value: this.chartlist['RCWO']['data'][0].cancount / this.chartlist['RCWO']['data'][0].sumcount,
                name: this.$t('home.homeCancel')
              }, {
                value: this.chartlist['RCWO']['data'][0].compcount / this.chartlist['RCWO']['data'][0].sumcount,
                name: this.$t('home.homeCompleted')
              }, {
                value: this.chartlist['RCWO']['data'][0].inprgcount / this.chartlist['RCWO']['data'][0].sumcount,
                name: this.$t('home.homeExecuting')
              }, {
                value: this.chartlist['RCWO']['data'][0].tocount / this.chartlist['RCWO']['data'][0].sumcount,
                name: this.$t('home.homeTimeout')
              }, {
                value: this.chartlist['RCWO']['data'][0].wfpcount / this.chartlist['RCWO']['data'][0].sumcount,
                name: this.$t('home.homeToExecuted')
              },
              ]
            }],
            color: colorlist

          });
        } else {
          this.chartlist['RCWO']['chartBox'].clear()
          this.chartlist['RCWO']['chartBox'].hideLoading()
          this.chartlist['RCWO']['chartBox'].setOption({
            title: {
              text: this.$t('home.noData'),
              top: 'center',
              left: 'center',
              textStyle: {
                fontSize: 14,
                fontWeight: 400,
              }
            }
          })
        }

        // this.chartlist['RCWO']['chartBox'].on('click', function(params) {
        // 	// 控制台打印数据的名称
        // 	console.log(params);
        //   });
      }


      // 工单
      if (this.chartlist['WO'] && this.$refs['wo']) {
        this.chartlist['WO']['chartBox'] = echarts.init(this.chartlist['WO']['dom'])
        if (this.chartlist['WO']['data'][0].sumcount > 0) {
          this.chartlist['WO']['chartBox'].hideLoading()
          this.chartlist['WO']['chartBox'].setOption({
            title: {
              show: false
            },
            tooltip: {
              // trigger: 'item',
              formatter: "{b} : {d}%"
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            series: [{
              type: 'pie',
              radius: ['45%', '65%'],
              center: ['50%', '35%'],

              itemStyle: {
                // borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: [{
                value: this.chartlist['WO']['data'][0].cancount / this.chartlist['WO']['data'][0].sumcount,
                name: this.$t('home.homeCancel')
              }, {
                value: this.chartlist['WO']['data'][0].compcount / this.chartlist['WO']['data'][0].sumcount,
                name: this.$t('home.homeCompleted')
              }, {
                value: this.chartlist['WO']['data'][0].inprgcount / this.chartlist['WO']['data'][0].sumcount,
                name: this.$t('home.homeExecuting')
              },
              {
                value: this.chartlist['WO']['data'][0].wfpcount / this.chartlist['WO']['data'][0].sumcount,
                name: this.$t('home.homeToExecuted')
              }, {
                value: this.chartlist['WO']['data'][0].pendingcount / this.chartlist['WO']['data'][0].sumcount,
                name: this.$t('home.homeShelve')
              }]
            }],
            color: woColorList

          });

        } else {
          this.chartlist['WO']['chartBox'].clear()
          this.chartlist['WO']['chartBox'].hideLoading()
          this.chartlist['WO']['chartBox'].setOption({
            title: {
              text: this.$t('home.noData'),
              top: 'center',
              left: 'center',
              textStyle: {
                fontSize: 14,
                fontWeight: 400,
              }
            }
          })
        }
      }


      // 事件报告
      if (this.chartlist['IR'] && this.$refs['ir']) {
        this.chartlist['IR']['chartBox'] = echarts.init(this.chartlist['IR']['dom'])
        if (this.chartlist['IR']['data'][0].sumcount > 0) {
          this.chartlist['IR']['chartBox'].hideLoading()
          this.chartlist['IR']['chartBox'].setOption({
            title: {
              show: false
            },
            tooltip: {
              // trigger: 'item',
              formatter: "{b} : {d}%"
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            series: [{
              type: 'pie',
              radius: ['45%', '65%'],
              center: ['50%', '35%'],

              itemStyle: {
                // borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              labelLine: {
                show: false
              },
              data: [{
                value: this.chartlist['IR']['data'][0].cancount / this.chartlist['IR']['data'][0].sumcount,
                name: this.$t('home.homeCancel')
              }, {
                value: this.chartlist['IR']['data'][0].compcount / this.chartlist['IR']['data'][0].sumcount,
                name: this.$t('home.homeCompleted')
              },
              {
                value: this.chartlist['IR']['data'][0].wfpcount / this.chartlist['IR']['data'][0].sumcount,
                name: this.$t('home.homeToExecuted')
              }]
            }],
            color: ircolorlist

          });
        } else {
          this.chartlist['IR']['chartBox'].clear()
          this.chartlist['IR']['chartBox'].hideLoading()
          this.chartlist['IR']['chartBox'].setOption({
            title: {
              text: this.$t('home.noData'),
              top: 'center',
              left: 'center',
              textStyle: {
                fontSize: 14,
                fontWeight: 400,
              }
            }
          })
        }
      }
    },
    // 获取图表系统配置列表
    getchartsyslist(callback) {
      this.$http.post('/chart/getchartsys', {
        hrid: this.$store.getters.loginid,
        penum: this.$store.getters.penum,
      }).then(result => {
        if (result.data.success) {
          this.chartShowTime = result.data.timerange
          this.chartShowData = result.data.chart.filter(item => item != 'chart6' && item != 'chart7')
          if (result.data.isshow) {
            // this.chartIsShow = true
            if (result.data.chart.length == 0) {
              result.data.content.forEach((item) => {
                if (item.type != 'chart6' && item.type != 'chart7') {
                  this.chartShowData.push(item.type)
                }
              })
            }
          }
          this.refreshrateChart = result.data.refreshrate
          clearInterval(this.timingRefreshChart)
          this.timingRefreshChart = null
          if (!(isNaN(parseInt(this.refreshrateChart)) || this.refreshrateChart == 0)) {
            this.timingRefreshChart = setInterval(() => {
              this.getAccountData()
              // this.getchartlist()
            }, parseInt(this.refreshrateChart) * 60 * 1000)
          }
          this.selectChartShowData = this.chartShowData
          this.chartsyslist = result.data.content.filter(item =>
            item.type != 'chart6' && item.type != 'chart7'
          )
          callback && callback()
        } else {
          this.$alert(result.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    getchartlist(itemParme) {
      let chartList = ['PERCWO', 'PEWO', 'PEIR', 'ABLOC', 'HRRC']
      this.$nextTick(() => {
        for (let key of chartList) {
          let domKey = key.toLowerCase()
          if (this.$refs[domKey]) {
            this.chartlist[key] = {
              data: null,
              dom: this.$refs[domKey]
            }
            this.showChartLoading(this.$refs[domKey])
          }
        }
        this.$http.post('/home/chartlist', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
          "charts": (itemParme && itemParme.length > 0) ? itemParme : this.chartShowData,
          "datascope": this.chartShowTime,
          isallpe: this.propertyState + '',
        }).then(result => {
          if (result.data.success) {
            for (let index in result.data) {
              if (this.$refs[index.toLocaleLowerCase()]) {
                if (itemParme && itemParme.length > 0) {
                  switch (itemParme[0]) {
                    case 'chart1':
                      if (index == 'PERCWO') {
                        this.chartlist[index] = {
                          data: result.data[index],
                          dom: this.$refs[index.toLocaleLowerCase()],
                          isEmpty: result.data[index].length == 0
                        }
                      }

                      break;
                    case 'chart2':
                      if (index == 'PEWO') {
                        this.chartlist[index] = {
                          data: result.data[index],
                          dom: this.$refs[index.toLocaleLowerCase()],
                          isEmpty: result.data[index].length == 0
                        }
                      }

                      break;
                    case 'chart3':
                      if (index == 'PEIR') {
                        this.chartlist[index] = {
                          // 当前物业取前5个
                          data: this.propertyState ? result.data[index] : result.data[index].slice(0, 5),
                          dom: this.$refs[index.toLocaleLowerCase()],
                          isEmpty: result.data[index].length == 0
                        }
                      }

                      break;
                    case 'chart4':
                      if (index == 'ABLOC') {
                        this.chartlist[index] = {
                          // 当前物业取前5个
                          data: this.propertyState ? result.data[index] : result.data[index].slice(0, 5),
                          dom: this.$refs[index.toLocaleLowerCase()],
                          isEmpty: result.data[index].length == 0
                        }
                      }

                      break;
                    case 'chart5':
                      if (index == 'HRRC') {
                        this.chartlist[index] = {
                          // 所有物业取前5个
                          data: this.propertyState ? result.data[index].slice(0, 5) : result.data[index],
                          dom: this.$refs[index.toLocaleLowerCase()],
                          isEmpty: result.data[index].length == 0
                        }
                      }

                      break;

                  }
                } else {
                  if (result.data[index].length > 0) {
                    this.chartlist[index] = {
                      data: result.data[index],
                      dom: this.$refs[index.toLocaleLowerCase()],
                      isEmpty: result.data[index].length == 0
                    }
                  }
                }

              }

            }
            this.createPieChart()
          } else {
            this.$alert(result.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })

          }
        })
      })
    },
    createPieChart() {
      // let colorlist = ["#29c98b", "#7cc8f8", "#ff5a5a", "#dddddd", "#FFA100", "#6860e9"];
      // 取消'#dddddd'、已完成'#0EB83A'、进行中'#E6FEFF'、超时'#ffc64b'、待执行'#7cc8f8'、暂搁置'#FFDDA6'
      let colorlist = ['#dddddd', '#0EB83A', '#E6FEFF', '#ffc64b', '#7cc8f8', '#FFDDA6']
      let woColorList = ['#dddddd', '#0EB83A', '#E6FEFF', '#7cc8f8', '#FFDDA6']

      // 巡检单
      if (this.chartlist['PERCWO'] && this.$refs['percwo']) {
        this.chartlist['PERCWO']['chartBox'] = echarts.init(this.chartlist['PERCWO']['dom'])
        if (Array.isArray(this.chartlist['PERCWO']['data']) && this.chartlist['PERCWO']['data'].length > 0) {
          let percwoData = [
            ['product', this.$t('home.homeCancel'), this.$t('home.homeCompleted'), this.$t('home.homeExecuting'), this.$t('home.homeTimeout'), this.$t('home.homeToExecuted')]
          ]
          this.chartlist['PERCWO']['data'].forEach((item) => {
            percwoData.push([
              this.propertyState ? item.pedesc : item.taskdesc,
              item.cancount,
              item.compcount,
              item.inprgcount,
              item.tocount,
              item.wfpcount,
            ])
          })
          this.chartlist['PERCWO']['chartBox'].hideLoading()
          this.chartlist['PERCWO']['chartBox'].setOption({
            title: { show: false },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            },
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              // borderWidth: 2
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            grid: {
              top: 35,
              right: 60,
              bottom: '20%',
              // top: '8%',
              // left: '3%',
              // right: '4%',
              // bottom: '25%',
              containLabel: true
            },
            dataset: {
              source: percwoData
            },
            xAxis: {
              name: this.$t('home.nameAxis'),
              type: 'category',
              axisLabel: {
                show: true, // 是否显示X轴的内容，不包含两端的文字
                interval: 0,
                fontSize: 10,
                rotate: 40, //倾斜的程度
                formatter: (params) => {
                  var newParamsName = ''// 最终拼接成的字符串
                  if (typeof params == 'string') {
                    var paramsNameNumber = this.countCharacters(params)// 实际标签的个数
                    var provideNumber = 8// 每行能显示的字的个数
                    if (window.innerWidth < 1445) provideNumber = 5
                    // if(window.innerWidth < 1445) provideNumber = 3
                    // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                    if (paramsNameNumber > provideNumber) {
                      // ********重点在这里********
                      newParamsName = params.substring(0, provideNumber) + '..'// 最终拼成的字符串
                    } else { // 将旧标签的值赋给新标签
                      newParamsName = params
                    }
                  }

                  // 将最终的字符串返回
                  return newParamsName
                }
              },
            },
            yAxis: {
              name: this.$t('home.amountAxis'),
              minInterval: 1,
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [{
              type: 'bar',
              barGap: '5%',
              itemStyle: {
                color: colorlist[0]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[1]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[2]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[3]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[4]
              },
            },
            ]
          })
        } else {
          if (this.chartlist['PERCWO'].isEmpty) {
            this.chartlist['PERCWO']['chartBox'].clear()
            this.chartlist['PERCWO']['chartBox'].hideLoading()
            this.chartlist['PERCWO']['chartBox'].setOption({
              title: {
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
          }

        }

      }


      // 物业工单
      if (this.chartlist['PEWO'] && this.$refs['pewo']) {
        this.chartlist['PEWO']['chartBox'] = echarts.init(this.chartlist['PEWO']['dom'])
        if (Array.isArray(this.chartlist['PEWO']['data']) && this.chartlist['PEWO']['data'].length > 0) {
          let pewoData = []
          let pewoName = []
          this.chartlist['PEWO']['data'].forEach((item, key) => {
            pewoName.push(this.propertyState ? item.pedesc : item.taskdesc,)
            if (key == 0) {
              pewoData.push({
                name: this.$t('home.homeCancel'),
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: woColorList[0]
                },
                label: {
                  formatter: (params) => {
                    return parseInt(params.value * 100) + '%'
                  }
                },
                data: [item.cancount / item.sumcount]
              })

              pewoData.push({
                name: this.$t('home.homeCompleted'),
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: woColorList[1]
                },
                label: {
                  formatter: (params) => {
                    return parseInt(params.value * 100) + '%'
                  }
                },
                data: [item.compcount / item.sumcount]
              })

              pewoData.push({
                name: this.$t('home.homeExecuting'),
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: woColorList[2]
                },
                label: {
                  formatter: (params) => {
                    return parseInt(params.value * 100) + '%'
                  }
                },
                data: [item.inprgcount / item.sumcount]
              })


              pewoData.push({
                name: this.$t('home.homeToExecuted'),
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: woColorList[3]
                },
                label: {
                  formatter: (params) => {

                    return parseInt(params.value * 100) + '%'
                  }
                },
                data: [item.wfpcount / item.sumcount]
              })

              pewoData.push({
                name: this.$t('home.homeShelve'),
                type: 'bar',
                stack: 'total',
                itemStyle: {
                  color: woColorList[4]
                },
                label: {
                  formatter: (params) => {

                    return parseInt(params.value * 100) + '%'
                  }
                },
                data: [item.pendingcount / item.sumcount]
              })


            } else {
              pewoData[0].data.push(item.cancount / item.sumcount)
              pewoData[1].data.push(item.compcount / item.sumcount)
              pewoData[2].data.push(item.inprgcount / item.sumcount)
              // pewoData[3].data.push(item.tocount / item.sumcount)
              pewoData[3].data.push(item.wfpcount / item.sumcount)
              pewoData[4].data.push(item.pendingcount / item.sumcount)
            }
          })
          this.chartlist['PEWO']['chartBox'].hideLoading()
          this.chartlist['PEWO']['chartBox'].setOption({
            title: { show: false },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                let showHtml = pewoName[params[0].dataIndex]
                params.forEach((item) => {
                  showHtml += '<div>' + item.marker + item.seriesName + '：' + parseInt(item.data * 100) + '%</div>'
                })
                return showHtml
              }
              // formatter: "{b} : {d}%"
            },
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              // borderWidth: 2
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            grid: {
              top: 35,
              right: 60,
              bottom: '20%',
              // top: '8%',
              // left: '5%',
              // right: '5%',
              // bottom: '25%',
              containLabel: true
            },
            xAxis: {
              name: this.$t('home.nameAxis'),
              type: 'category',
              data: pewoName,
              axisLabel: {
                show: true, // 是否显示X轴的内容，不包含两端的文字
                interval: 0,
                fontSize: 10,
                rotate: 40, //倾斜的程度
                formatter: (params) => {
                  var newParamsName = ''// 最终拼接成的字符串
                  if (typeof params == 'string') {
                    var paramsNameNumber = this.countCharacters(params)// 实际标签的个数
                    var provideNumber = 8// 每行能显示的字的个数
                    if (window.innerWidth < 1445) provideNumber = 5
                    // if(window.innerWidth < 1445) provideNumber = 3
                    // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                    if (paramsNameNumber > provideNumber) {
                      // ********重点在这里********
                      newParamsName = params.substring(0, provideNumber) + '..'// 最终拼成的字符串
                    } else { // 将旧标签的值赋给新标签
                      newParamsName = params
                    }
                  }

                  // 将最终的字符串返回
                  return newParamsName
                }
              },
            },
            yAxis: {
              name: this.$t('home.percentageAxis'),
              type: 'value',
              axisLabel: {
                formatter: (params) => {
                  // console.log('params',params)
                  // let showHtml = ''
                  // params.forEach((item) => {
                  // 	showHtml += '<div>' + item.marker + item.seriesName + '：' + parseInt(item.data * 100) + '%</div>'
                  // })
                  return parseInt(params * 100) + '%'
                }
              }
            },
            series: pewoData,
          })

        } else {
          if (this.chartlist['PEWO'].isEmpty) {
            this.chartlist['PEWO']['chartBox'].clear()
            this.chartlist['PEWO']['chartBox'].hideLoading()
            this.chartlist['PEWO']['chartBox'].setOption({
              title: {
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
          }
        }
      }



      // 事件报告
      if (this.chartlist['PEIR'] && this.$refs['peir']) {
        this.chartlist['PEIR']['chartBox'] = echarts.init(this.chartlist['PEIR']['dom'])
        if (Array.isArray(this.chartlist['PEIR']['data']) && this.chartlist['PEIR']['data'].length > 0) {

          let peirData = [
            ['product']
          ]
          let seriesArr = []
          this.chartlist['PEIR']['data'].forEach((item, key) => {
            let tempItemArr = this.propertyState ? [item.pedesc] : [item.locdesc]
            // let colorKey = 0
            for (let ii in item) {
              if (ii == 'cancount' || ii == 'compcount' || ii == 'inprgcount' || ii == 'tocount' || ii == 'wfpcount') tempItemArr.push(item[ii])

              if (key == 0) {
                if (ii == 'cancount') {
                  peirData[0].push(this.$t('home.homeCancel'))
                  seriesArr.push({
                    type: 'bar',
                    itemStyle: {
                      color: colorlist[0]
                    },
                  })
                }
                if (ii == 'compcount') {
                  peirData[0].push(this.$t('home.homeCompleted'))
                  seriesArr.push({
                    type: 'bar',
                    itemStyle: {
                      color: colorlist[1]
                    },
                  })
                }
                if (ii == 'inprgcount') {
                  peirData[0].push(this.$t('home.homeExecuting'))
                  seriesArr.push({
                    type: 'bar',
                    itemStyle: {
                      color: colorlist[2]
                    },
                  })
                }
                if (ii == 'tocount') {
                  peirData[0].push(this.$t('home.homeTimeout'))
                  seriesArr.push({
                    type: 'bar',
                    itemStyle: {
                      color: colorlist[3]
                    },
                  })
                }
                if (ii == 'wfpcount') {
                  peirData[0].push(this.$t('home.homeToExecuted'))
                  seriesArr.push({
                    type: 'bar',
                    itemStyle: {
                      color: colorlist[4]
                    },
                  })
                }
              }
              // colorKey++
            }
            peirData.push(tempItemArr)

          })
          this.chartlist['PEIR']['chartBox'].hideLoading()
          this.chartlist['PEIR']['chartBox'].setOption({
            title: {
              show: false
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            },
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              // borderWidth: 2
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            grid: {
              top: 35,
              right: 60,
              bottom: '10%',
              // top: '8%',
              // left: '3%',
              // right: '4%',
              // bottom: '10%',
              containLabel: true
            },
            dataset: {
              source: peirData
            },
            // axisTick:{
            // 	interval:'0'
            // },
            xAxis: {
              name: this.$t('home.nameAxis'),
              type: 'category',
              axisLabel: {
                show: true, // 是否显示X轴的内容，不包含两端的文字
                interval: 0,
                fontSize: 10,
                rotate: 40, //倾斜的程度
                formatter: (params) => {
                  var newParamsName = ''// 最终拼接成的字符串
                  if (typeof params == 'string') {
                    var paramsNameNumber = this.countCharacters(params)// 实际标签的个数
                    var provideNumber = 8// 每行能显示的字的个数
                    if (window.innerWidth < 1445) provideNumber = 5
                    // if(window.innerWidth < 1445) provideNumber = 3
                    // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                    if (paramsNameNumber > provideNumber) {
                      // ********重点在这里********
                      newParamsName = params.substring(0, provideNumber) + '..'// 最终拼成的字符串
                    } else { // 将旧标签的值赋给新标签
                      newParamsName = params
                    }
                  }

                  // 将最终的字符串返回
                  return newParamsName
                }
              },
            },
            yAxis: {
              name: this.$t('home.amountAxis'),
              minInterval: 1,
            },
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: seriesArr

          })
        } else {
          if (this.chartlist['PEIR'].isEmpty) {
            this.chartlist['PEIR']['chartBox'].clear()
            this.chartlist['PEIR']['chartBox'].hideLoading()
            this.chartlist['PEIR']['chartBox'].setOption({
              title: {
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
          }
        }

      }

      // 异常巡检点
      if (this.chartlist['ABLOC'] && this.$refs['abloc']) {
        this.chartlist['ABLOC']['chartBox'] = echarts.init(this.chartlist['ABLOC']['dom'])
        if (Array.isArray(this.chartlist['ABLOC']['data']) && this.chartlist['ABLOC']['data'].length > 0) {
          let ablocData = []
          let ablocName = []
          this.chartlist['ABLOC']['data'].forEach((item) => {
            ablocName.push(this.propertyState ? item.pedesc : item.locdesc)
            ablocData.push(item.sumcount)
          })
          this.chartlist['ABLOC']['chartBox'].hideLoading()
          this.chartlist['ABLOC']['chartBox'].setOption({
            title: { show: false },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              formatter: (params) => {
                let showHtml = ''
                params.forEach((item) => {
                  showHtml += '<div>' + item.marker + ablocName[params[0].dataIndex] + '：' + params[0].data + '</div>'
                })
                return showHtml
              }
            },
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              // borderWidth: 2
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              show: false,
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            grid: {
              top: 35,
              right: 70,
              bottom: '10%',
              // top: '8%',
              // left: '3%',
              // right: '13%',
              // bottom: '10%',
              containLabel: true
            },
            xAxis: {
              name: this.$t('home.amountAxis'),
              max: 'dataMax',
              minInterval: 1,
            },
            yAxis: {
              name: this.$t('home.nameAxis'),
              type: 'category',
              data: ablocName,
              axisLabel: {
                show: true, // 是否显示X轴的内容，不包含两端的文字
                interval: 0,
                formatter: function (params) {
                  var newParamsName = ''// 最终拼接成的字符串
                  if (typeof params == 'string') {
                    var paramsNameNumber = params.length// 实际标签的个数
                    var provideNumber = 10// 每行能显示的字的个数
                    if (window.innerWidth < 1445) provideNumber = 5
                    // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                    if (paramsNameNumber > provideNumber) {
                      // ********重点在这里********
                      newParamsName = params.substring(0, provideNumber) + '..'// 最终拼成的字符串
                    } else { // 将旧标签的值赋给新标签
                      newParamsName = params
                    }
                  }

                  // 将最终的字符串返回
                  return newParamsName
                }
              },
              // max: 2 // only the largest 3 bars will be displayed
            },
            series: [{
              name: 'X',
              type: 'bar',
              data: ablocData,
              label: {
                show: true,
                position: 'right',
                // valueAnimation: true
              }
            }],
            color: ['#ff5a5a']
          })
        } else {
          if (this.chartlist['ABLOC'].isEmpty) {
            this.chartlist['ABLOC']['chartBox'].clear()
            this.chartlist['ABLOC']['chartBox'].hideLoading()
            this.chartlist['ABLOC']['chartBox'].setOption({
              title: {
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
          }
        }

      }


      // 人员巡检情况
      if (this.chartlist['HRRC'] && this.$refs['hrrc']) {
        this.chartlist['HRRC']['chartBox'] = echarts.init(this.chartlist['HRRC']['dom'])
        if (Array.isArray(this.chartlist['HRRC']['data']) && this.chartlist['HRRC']['data'].length > 0) {
          let hrrcData = [
            ['product', this.$t('home.homeCancel'), this.$t('home.homeCompleted'), this.$t('home.homeExecuting'), this.$t('home.homeTimeout'), this.$t('home.homeToExecuted')]
          ]

          this.chartlist['HRRC']['data'].forEach((item) => {
            if (this.propertyState) {
              let currentItem = hrrcData.find(e => e[0] == item.pedesc)
              if (currentItem) {
                currentItem[1] = currentItem[1] + item.cancount
                currentItem[2] = currentItem[2] + item.compcount
                currentItem[3] = currentItem[3] + item.inprgcount
                currentItem[4] = currentItem[4] + item.tocount
                currentItem[5] = currentItem[5] + item.wfpcount
              } else {
                hrrcData.push([
                  item.pedesc,
                  item.cancount,
                  item.compcount,
                  item.inprgcount,
                  item.tocount,
                  item.wfpcount,
                ])
              }
            } else {
              hrrcData.push([
                item.hrname || '',
                item.cancount,
                item.compcount,
                item.inprgcount,
                item.tocount,
                item.wfpcount,
              ])
            }
          })
          this.chartlist['HRRC']['chartBox'].hideLoading()
          this.chartlist['HRRC']['chartBox'].setOption({
            title: { show: false },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            },
            itemStyle: {
              borderRadius: 10,
              // borderColor: '#fff',
              // borderWidth: 2
            },
            legend: {
              // orient: 'vertical',
              left: 'center',
              bottom: 'bottom',
              itemWidth: 12,
              itemHeight: 14,
              // data: piedesclist
            },
            grid: {
              top: 35,
              right: 60,
              bottom: '20%',
              // top: '8%',
              // left: '3%',
              // right: '4%',
              // bottom: '25%',
              containLabel: true
            },
            dataset: {
              source: hrrcData
            },
            xAxis: {
              name: this.$t('home.nameAxis'),
              type: 'category',
              axisLabel: {
                show: true, // 是否显示X轴的内容，不包含两端的文字
                interval: 0,
                fontSize: 10,
                rotate: 40, //倾斜的程度
                formatter: (params) => {
                  var newParamsName = ''// 最终拼接成的字符串
                  if (typeof params == 'string') {
                    var paramsNameNumber = this.countCharacters(params)// 实际标签的个数
                    var provideNumber = 8// 每行能显示的字的个数
                    if (window.innerWidth < 1445) provideNumber = 5
                    // if(window.innerWidth < 1445) provideNumber = 3
                    // 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                    if (paramsNameNumber > provideNumber) {
                      // ********重点在这里********
                      newParamsName = params.substring(0, provideNumber) + '..'// 最终拼成的字符串
                    } else { // 将旧标签的值赋给新标签
                      newParamsName = params
                    }
                  }

                  // 将最终的字符串返回
                  return newParamsName
                }
              },
            },
            yAxis: {
              name: this.$t('home.amountAxis'),
              minInterval: 1,
            },
            series: [{
              type: 'bar',
              barGap: '5%',
              itemStyle: {
                color: colorlist[0]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[1]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[2]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[3]
              },
            },
            {
              type: 'bar',
              itemStyle: {
                color: colorlist[4]
              },
            },
            ]
          })
        } else {
          if (this.chartlist['HRRC'].isEmpty) {
            this.chartlist['HRRC']['chartBox'].clear()
            this.chartlist['HRRC']['chartBox'].hideLoading()
            this.chartlist['HRRC']['chartBox'].setOption({
              title: {
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
          }
        }

      }

    },
    // 图表文字切割
    countCharacters(str) {
      var totalCount = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
          totalCount++;
        }
        else {
          totalCount += 2;
        }
      }
      return totalCount;
    },
    // 判断图表是否有权显示
    isChartShow(itemType) {
      if (this.chartShowData.indexOf(itemType) > -1) {
        return true
      }
      return false
    },
    // 配置仪表盘
    getDashboard() {

      this.$http
        .post('/dashboard/getDashboard', {
          hrid: this.$store.getters.loginid,
          penum: this.$store.getters.penum,
        }).then(result => {
          if (result.data.success) {
            this.dashboard = result.data.content
            // console.log('this.dashboard', this.dashboard);
            this.dashboardnum = result.data.dashboardnum
            this.refreshrateDashboard = result.data.refreshrate
            let meters = []
            this.dashboard.forEach(element => {
              element.indexcode.forEach(elementItem => {
                meters.push(element.cmnum + '|' + elementItem.indexcode)
                // if (this.dashboardConfig.indexOf(element.cmnum + '|' + elementItem.indexcode) == -1) {
                //   this.dashboardConfig.push(element.cmnum + '|' + elementItem.indexcode)
                // }

              });
            });
            this.dashboardConfig = meters

            clearInterval(this.timingRefreshDashboard)
            this.timingRefreshDashboard = null
            if (!(isNaN(parseInt(this.refreshrateDashboard)) || this.refreshrateDashboard == 0)) {
              // console.log(isNaN(parseInt(this.refreshrateDashboard)))
              this.timingRefreshDashboard = setInterval(() => {
                // console.log('----------111jishi')
                // this.getchartlist()
                this.getDashboardsingle()
              }, parseInt(this.refreshrateDashboard) * 60 * 1000)
            }

            this.getDashboardsingle()
          } else {
            this.$alert(result.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
    },
    // 获取仪表数据
    getDashboardsingle() {
      this.dashboardlist = []
      this.pirChartlist = {}
      this.dashboard.forEach((item) => {
        // if (key < 7) {
        this.$http
          .post('/dashboard/dashboardlist', {
            hrid: this.$store.getters.loginid,
            penum: this.$store.getters.penum,
            cmnum: item.cmnum
          }).then(result => {
            if (result.data.success) {
              let resultTemp = {
                cmdesc: result.data.cmdesc,
                cmnum: result.data.cmnum,
                dashboardnum: result.data.dashboardnum,
                index: []
              }
              result.data['index'].forEach((itemIndex) => {
                itemIndex.cmnum = item.cmnum
                // 当前图表的类型
                itemIndex.chartType = 'normal'
                // 当前图表的时间过滤弹窗
                // itemIndex.popperVisible = false
                // 开始时间和结束时间
                itemIndex.datetime = []
                itemIndex.startdate = ''
                itemIndex.enddate = ''
                // 设置图表唯一id
                itemIndex.id = `${itemIndex.indexcode.toLowerCase()}_${itemIndex.cmnum}`
                resultTemp.index.push(itemIndex)
              })
              let dashboardlist = []
              dashboardlist.push(resultTemp)
              this.dashboardlist = this.dashboardlist.concat(dashboardlist)
              this.$nextTick(() => {
                // setTimeout(() => {
                this.dashboardlist.forEach((dashboardElement, elementKey) => {
                  dashboardElement['index'].forEach((dashboardItem, itemKey) => {
                    if (dashboardItem.indexcode == "PIR") {
                      this.setDashboardChart(dashboardItem, elementKey, itemKey)
                    }

                  })
                })

                // })

              })
            } else {
              this.$alert(result.data.message, this.$t('common.alertPrompt'), {
                type: 'error',
                center: true
              })
            }
          })

      })
    },
    setDashboardChart(dashboardItem, elementKey, itemKey) {
      let colorlist = ['#BEC0D2', '#FF7234', '#0053D0', '#FF9649', '#9013FE', '#FF9649']
      // this.dashboardlist.forEach((dashboardElement, elementKey) => {
      //   dashboardElement['index'].forEach((dashboardItem, itemKey) => {
      let nameArr = []
      let valueArr = []
      let meterData = JSON.parse(JSON.stringify(dashboardItem))
      // console.log('this.$refs[dashboardItem.id]',this.$refs[dashboardItem.id],dashboardItem.id)
      if (!this.pirChartlist[dashboardItem.id]) {
        this.pirChartlist[dashboardItem.id] = echarts.init(this.$refs[dashboardItem.id][0])
      }

      // 红外线
      if (dashboardItem.indexcode == "PIR") {
        let dateList = []
        let valueList = []
        meterData.pirreading.sort((a, b) => new Date(a.newreadingdate).getTime() - new Date(b.newreadingdate).getTime())
        meterData.pirreading.forEach(readItem => {
          dateList.push(this.$utils.format(new Date(readItem.newreadingdate), 'Y-m-d H:i'))
          valueList.push(readItem.newreading)
        })

        nameArr = dateList
        valueArr = valueList

        if (valueArr.length == 0) {
          this.pirChartlist[dashboardItem.id].clear()
          this.pirChartlist[dashboardItem.id].setOption({
            title: {
              show: true,
              text: this.$t('home.noData'),
              top: 'center',
              left: 'center',
              textStyle: {
                fontSize: 14,
                fontWeight: 400,
              }
            }
          })

        }
        this.pirChartlist[dashboardItem.id].setOption({
          title: {
            show: false,
          },
          tooltip: {
            show: true,
            confine: true,
            formatter: (params) => {
              return `<div>
                  ${params.marker}${this.$utils.format(new Date(params.name), 'Y-m-d H:i')}<p style="margin-left: 15px;">${params.value}</p>
                  </div>`
            },
          },
          dataZoom: [{
            type: 'inside',
            start: 0
          }],
          xAxis: {
            type: 'category',
            data: nameArr,
            axisLabel: {
              formatter: (e) => {
                // return item.id == 'chart_detail' ? new Date(e).format('yyyy-MM-dd hh:mm') : new Date(e).format('hh:mm')
                return this.$utils.format(new Date(e), 'H:i')
              }
            },
          },
          yAxis: {
            type: 'value',
            minInterval: 1
          },
          grid: {
            top: '5%',
            left: '20%',
            bottom: '20%'
          },
          series: [
            {
              data: valueArr,
              type: 'bar',
              itemStyle: {
                color: '#ffa000'
              },
              label: {
                // show: item.id == 'chart_detail',
                position: 'top',
                formatter: (params) => {
                  return params.value && params.value != 0 ? params.value : ''
                }
              }
            }
          ]
        });
        return
      } else {
        this.pirChartlist[dashboardItem.id].clear()
        this.pirChartlist[dashboardItem.id].setOption({
          series: [
            {
              type: 'gauge',
              progress: {
                show: true,
                width: 18
              },
              itemStyle: {
                color: colorlist[Math.abs(colorlist.length - (elementKey + itemKey))]
              },
              axisLine: {
                lineStyle: {
                  width: 18
                }
              },
              splitLine: {
                length: 15,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
              },
              pointer: {
                show: false
              },
              axisLabel: {
                distance: 25,
                color: '#575777',
                fontSize: 20,
                show: false
              },
              title: {
                offsetCenter: [0, '23%'],
                fontSize: 14,
                color: '#575777'
              },
              detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 40,
                borderRadius: 8,
                offsetCenter: [0, 0],
                fontSize: 30,
                fontWeight: 'bold',
                // formatter: `{value} ${dashboardItem.unit}`,
                color: dashboardItem.newreading <= dashboardItem.islowlimit || dashboardItem.newreading >= dashboardItem.istoplimit ? '#ff0000' : '#23233C'
              },
              data: [
                {
                  value: dashboardItem.newreading || 0,
                  name: dashboardItem.unit
                }
              ]
            }
          ]
        });
      }
      //   })
      // })
    },
    // 切换仪表图表
    changeChartType(item) {
      if (this.pirChartlist[item.id]) {
        this.pirChartlist[item.id].dispose()
      }

      // console.log('---item',item)
      item.chartType = item.chartType == 'normal' ? 'line' : 'normal'
      if (item.chartType == 'line') {
        let isEmpty = !item.meterreadinglist || (item.meterreadinglist && item.meterreadinglist.length == 0)
        this.$nextTick(() => {
          this.setLineChartOptions(item, isEmpty)
        })
      } else {
        // this.$nextTick(() => {
        //   if (!this.pirChartlist[item.id]) {
        //     this.pirChartlist[item.id] = echarts.init(this.$refs[item.id][0])
        //   }
        //   this.pirChartlist[item.id].clear()
        // })

        // this.setDashboardChart(item, elementKey, itemKey)
      }

    },
    // 切换测量仪表图表和光线图表
    setLineChartOptions(item, isEmpty) {
      if (item.chartType == 'line') {

        if (this.$refs[item.id]) {
          // if (!this.pirChartlist[item.id]) {
          this.pirChartlist[item.id] = echarts.init(this.$refs[item.id][0])
          // }
          if (isEmpty) {
            this.pirChartlist[item.id].clear()
            this.pirChartlist[item.id].setOption({
              title: {
                show: true,
                text: this.$t('home.noData'),
                top: 'center',
                left: 'center',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 400,
                }
              }
            })
            return
          }
          let meterData = JSON.parse(JSON.stringify(item))
          // console.log('meterData', meterData);

          // 排序（升序）
          meterData.meterreadinglist.sort((a, b) => new Date(a.newreadingdate).getTime() - new Date(b.newreadingdate).getTime())
          // 设置成二维数组
          let dataTwoList = meterData.meterreadinglist.map(e => [e.newreadingdate, e.newreading])
          // 时间列表
          let timeList = dataTwoList.map(time => this.$utils.format(new Date(time[0]), 'Y-m-d H:i'))
          // 数据列表
          let dataList = dataTwoList.map(time => time[1])
          // 求最大值，又来设置颜色取值域的
          let max = Math.max(...meterData.meterreadinglist.map(e => parseFloat(e.newreading)))
          // 测量仪表 折线图
          // 光线  阶梯折线图 smooth: false, series.step: 'end'
          this.pirChartlist[item.id].setOption({
            title: {
              show: false
            },
            tooltip: {
              show: true,
              formatter: (params) => {
                return `<div>
								${params.marker}${this.$utils.format(new Date(params.name), 'Y-m-d H:i')}<p style="margin-left: 15px;">${params.value} ${meterData.unit}</p>
								</div>`
              },
              confine: true
            },
            dataZoom: [{
              type: 'inside',
              start: 0
            }],
            grid: {
              top: '5%',
              left: '20%',
              bottom: '20%'
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                formatter: (e) => {
                  return this.$utils.format(new Date(e), 'H:i')
                }
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              },
              data: timeList
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed'
                }
              }
            },
            visualMap: {
              show: false,
              pieces: [
                {
                  lte: meterData.islowlimit,
                  color: '#FF0000'
                },
                {
                  lt: meterData.istoplimit,
                  gt: meterData.islowlimit,
                  color: '#FFA000'
                },
                {
                  gt: meterData.istoplimit,
                  lte: meterData.istoplimit + max,
                  color: '#FF0000'
                }
              ],
              outOfRange: {
                color: '#999'
              }
            },
            series: [
              {
                data: dataList,
                type: 'line',
                smooth: item.indexcode == 'LIGHT' ? false : true,
                step: item.indexcode == 'LIGHT' ? 'end' : false,
                symbolSize: 6,
                lineStyle: {
                  width: 1,
                },
                areaStyle: {
                  color: 'rgba(255, 160, 0, 0.3)'
                },
                markLine: {
                  symbol: [],
                  label: {
                    show: false
                  },
                  data: [{
                    yAxis: meterData.istoplimit,
                    lineStyle: {
                      color: '#ff0000',
                      type: 'dashed'
                    },
                  },
                  {
                    yAxis: meterData.islowlimit,
                    lineStyle: {
                      color: '#ff0000',
                      type: 'dashed'
                    },
                  }],
                  silent: true
                }
              }
            ]
          })
        }
      }
    },
    // 设置仪表弹窗
    settingWay() {
      this.configEditData = {
        dashboardConfig: this.dashboardConfig,
        refreshrateDashboard: this.refreshrateDashboard,
        selectChartShowData: this.selectChartShowData,
        chartShowTime: this.chartShowTime,
        refreshrateChart: this.refreshrateChart,
        dashboardnum: this.dashboardnum
      }
      console.log('this.configEditData', this.configEditData);

      this.configDashboardVisible = true

    },
    cancelConfigClick() {
      this.configDashboardVisible = false
    },
    setConfigSuccess(selectChartShowData) {
      this.chartShowData = selectChartShowData
      this.getchartsyslist(() => {
        // this.chartlist = {}
        this.chartShowData.forEach((element) => {
          this.getchartlist([element])
        })

      })
      this.getDashboard()
      this.configDashboardVisible = false
    },
    // 打开仪表图表
    openMeterChartShow(data) {
      this.meterChartDetail = data
      this.dialogMeterChartVisible = true
    },
    // 获取用户详情
    getAccountDetail(type) {
      this.dialogAccountType = type
      this.dialogAccountDetailVisible = true
      // switch (type) {
      //   case 'pe':
      //     if (!this.propertyState) return
      //     this.accountDetail.type = 'pe'
      //     this.accountDetail.functionName = this.getHomeList
      //     this.accountDetail.title = this.pageText.PROENTITY
      //     this.accountDetail.titleNum = 'pe_count'
      //     this.getHomeList()
      //     break;

      //   case 'acc':
      //     this.accountDetail.type = 'acc'
      //     this.accountDetail.functionName = this.getAccountList
      //     this.accountDetail.title = this.pageText.HOMEACCOUNT
      //     this.accountDetail.titleNum = 'acc_count'
      //     this.getAccountList()
      //     break;

      //   case 'loc':
      //     this.accountDetail.type = 'loc'
      //     this.accountDetail.functionName = this.getLoclist
      //     this.accountDetail.title = this.pageText.HOMELOC
      //     this.accountDetail.titleNum = 'loc_count'
      //     this.getLoclist()
      //     break;

      //   case 'rctask':
      //     this.accountDetail.type = 'rctask'
      //     this.accountDetail.functionName = this.getrctaskList
      //     this.accountDetail.title = this.pageText.INSTASK
      //     this.accountDetail.titleNum = 'rctask_count'
      //     this.getrctaskList()
      //     break;

      //   case 'wotemp':
      //     this.accountDetail.type = 'wotemp'
      //     this.accountDetail.functionName = this.getwotempList
      //     this.accountDetail.title = this.pageText.WOTEMPLATE
      //     this.accountDetail.titleNum = 'wotemp_count'
      //     this.getwotempList()
      //     break;

      //   default:
      //     break;
      // }
    }
  }
}